import Box from "@gtg/components/box";
import Container from "@gtg/components/container";
import Seo from "@gtg/components/seo";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArticleLayout from "../components/articleLayout";
import Category from "../components/category";
import GlobalWrapper from "../components/globalWrapper";
import Subtitle from "../components/subtitle";
import Img2Src from "../images/articles/airpressure/Manometer.jpg";
import { color } from "../styles/theme";

const BottomBox = styled(Box)`
  background: ${color.coal50};
  border-radius: 10px;
  box-shadow: 0px 10px 40px #e3ddd4;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BoxImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 500px;
`;

const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Lenzbox Blog</Category>
            <hr />
            <h1>Was haben Autoreifen und Kontaktlinsen gemeinsam?</h1>
            <Subtitle>Wir lüften das Geheimnis des Luftdrucks</Subtitle>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img2Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <p>
              Das Geheimnis, was <b>Autoreifen und Kontaktlinsen gemeinsam</b>{" "}
              haben, lässt sich lüften, wenn wir uns anschauen, wie die{" "}
              <b>Kontaktlinsenpflege</b> in der Lenzbox funktioniert.
            </p>
            <p>
              <h2>Linsenpflege in der Lenzbox</h2>
              Die Linsen werden in der <b>Linsenkammer</b> von Flüssigkeit
              umspült. Das intelligente Design der Linsenpflegebox sorgt dafür,
              dass keine Luft und keine Verunreinigungen in die Linsenkammer
              gelangen. Dies sorgt für eine <b>optimale Hygiene.</b>{" "}
              Gleichzeitig darf aber auch keine Flüssigkeit austreten. Folglich
              ist es wichtig, dass die Linsenkammer und die
              Flüssigkeitsschläuche <b>absolut dicht</b> sind. Das gleiche gilt
              für Autoreifen: die Reifen müssen absolut dicht sein, es darf
              nichts aus den Schläuchen austreten.
            </p>
            <h2>Robust bei hohem Druck</h2>
            <p>
              Die Dichtigkeit ist nicht die einzige Gemeinsamkeit von
              Kontaktlinsenpflege und Autopflege. Sowohl in den Reifen als auch
              in den Linsenkammern entsteht ein <b>Überdruck.</b> Jeder
              Autofahrer kennt das Prinzip. Ist der Reifendruck zu niedrig, wird
              der Bremsweg länger und die Kurvenlage schlechter. Es besteht
              sogar die Gefahr, dass der Reifen platzt. Meist beträgt der
              Luftdruck in den Reifen zwischen 2 und 3&nbsp;bar. Das klingt nach
              viel – per Hand möchte man keinen Reifen aufblasen. Die Lenzbox
              geht aber noch darüber hinaus. Bereits während der Entwicklung der
              Lenzbox haben wir viele Tests im Labor durchgeführt. Eine wichtige
              Fragestellung dabei war, wie hoch der Druck sein kann, ohne dass
              die Lenzbox undicht wird oder Flüssigkeit ungewollt austritt. Die
              Labortests haben das zuverlässige Design der Lenzbox bestätigt.
              Selbst <b>4&nbsp;bar Druck in der Linsenkammer</b> hat die Lenzbox
              einwandfrei ohne Undichtigkeit überstanden.
              Somit besteht auch bei falscher Bedienung oder in rauen Umgebungen
              keinerlei Gefahr, dass ein versehentlich erzeugter zu hoher Druck
              zu Problemen führen könnte.
            </p>
            <h2>Trockene Entnahme der Linsen</h2>
            <p>
              Wenn die perfekt gepflegten Kontaktlinsen schließlich aus der{" "}
              <Link to="/">Lenzbox</Link> entnommen werden, spielt wieder der
              Druck eine wichtige Rolle. Denn dann kann auf Knopfdruck die
              Flüssigkeit gezielt entweichen, so dass die Linsen problemlos aus
              der Linsenkammer entnommen und in das Auge eingesetzt werden
              können. Dabei kommt der Nutzer der Lenzbox nicht mit der
              gebrauchten Flüssigkeit in Kontakt. Denn der Druck hat dafür
              gesorgt, dass die Flüssigkeit zuvor geordnet entsorgt wurde. Die{" "}
              <Link to="/">Lenzbox</Link>, das neue Zuhause für deine
              Kontaktlinsen, ist eine rundherum{" "}
              <b>saubere und zugleich höchst komfortable Lösung</b> zur Pflege
              und Aufbewahrung.
            </p>
            <p>
              <b>
                Unser Ziel: die höchstmögliche Wertigkeit deines zukünftigen
                Kontaktlinsenbehälters!
              </b>
            </p>
          </Box>
        </Container>

        <Box px={0} pt={0}>
          <Container wMax="md">
            <BottomBox pxMax="xl" pyMax="lg">
              <h2>Kontaktlinsenpflege ganz einfach</h2>
              <p>
                Das Pflegen, Reinigen und Aufbewahren der Kontaktlinsen ist
                umständlich und seit vielen Jahren unverändert. Mit der{" "}
                <Link to="/">Lenzbox</Link> schaffen wir hier echten
                Fortschritt: Ansprechendes Design, hohe Nutzerfreundlichkeit,
                beste Hygiene und Verringerung von Müll. Das sind
                Innovationsgeist und Qualität „Made in Germany“.
              </p>
            </BottomBox>
          </Container>
        </Box>
      </ArticleLayout>
    </GlobalWrapper>
  );
};

export default ArticlePage;
